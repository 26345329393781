import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import classNames from "classnames"

import postStyles from "./post.module.css"

const createPostPath = (date, slug) => {
  return `/${date.replace(/-/g, "/")}/${slug}`
}

const navLink = node => {
  const {
    frontmatter: { title, date, slug },
  } = node
  return (
    <Link className={postStyles.bottomNavLink} to={createPostPath(date, slug)}>
      {title}
    </Link>
  )
}

const Tags = props => {
  const { tags } = props
  if (tags && tags.length === 0) {
    return <span />
  } else {
    return (
      <ul className={postStyles.tags}>
        {tags &&
          tags.map(tag => {
            const prettyTag = tag.replace(/-/g, " ")
            return (
              <li className={postStyles.tagItem} key={tag}>
                <Link
                  className={postStyles.tagItemLink}
                  key={tag}
                  to={`/tags/${tag}`}
                >
                  {prettyTag}
                </Link>
              </li>
            )
          })}
      </ul>
    )
  }
}

function Post({ node, summaryView, prev, next, disclosureHtml }) {
  const { frontmatter, html, excerpt } = node
  const { title, date, slug, tags } = frontmatter
  return (
    <div
      className={classNames("blog-post-container", {
        [postStyles.summary]: summaryView,
      })}
    >
      <div className="blog-post">
        {summaryView ? (
          <Link to={createPostPath(date, slug)}>
            <h1 className={postStyles.title}>{title}</h1>
          </Link>
        ) : (
          <h1 className={postStyles.title}>{title}</h1>
        )}
        <div className={postStyles.date}>{date}</div>
        {summaryView ? (
          <p className={classNames("blog-post-content", postStyles.body)}>
            {excerpt}
          </p>
        ) : (
          <div>
            <div
              className={classNames("blog-post-content", postStyles.body)}
              dangerouslySetInnerHTML={{ __html: html }}
            />
            <Tags tags={tags} />
          </div>
        )}
      </div>
      {!summaryView && (
        <div>
          <div className={postStyles.bottomNav}>
            {next && (
              <div className={postStyles.navLinkContainer}>
                Next post: {navLink(next)}
              </div>
            )}
            {prev && (
              <div className={postStyles.navLinkContainer}>
                Previous post: {navLink(prev)}
              </div>
            )}
          </div>
          <div className={postStyles.disclosure}
          dangerouslySetInnerHTML={{__html: disclosureHtml}}
          >

          </div>
        </div>
      )}
    </div>
  )
}

Post.propTypes = {
  node: PropTypes.shape({
    frontmatter: PropTypes.shape({
      title: PropTypes.string,
      date: PropTypes.string,
      slug: PropTypes.string,
      tags: PropTypes.arrayOf(PropTypes.string),
    }),
    html: PropTypes.string,
    excerpt: PropTypes.string,
  }),
  summaryView: PropTypes.bool,
}

export default Post
