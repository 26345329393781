import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Post from "../components/post"

export default function Template({
  data, pageContext
}) {
  const { markdownRemark, disclosureData } = data
  const { prev, next } = pageContext
  const { frontmatter: { title } } = markdownRemark
  return (
    <Layout>
      <SEO title={title} />
      <Post node={markdownRemark} prev={prev} next={next} disclosureHtml={disclosureData.edges[0].node.html} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query($slug: String!, $date: Date!) {
    markdownRemark(frontmatter: { slug: { eq: $slug }, date: { eq: $date } }) {
      html
      frontmatter {
        date(formatString: "YYYY-MM-DD")
        title
        tags
      }
    },
    disclosureData: allMarkdownRemark(filter: { frontmatter: { templateKey: { eq: "disclosure-copy" } } }) {
      edges {
        node {
            html
        }
      }
    }

  }
`